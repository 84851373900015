<script>
  import { fade } from "svelte/transition";

  export let contentData = null;

</script>

<div class="wrapper">
  <iframe src="https://kuroi.atabook.org/" frameborder="0"></iframe>
</div>

<style lang="scss">
  .wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;

    background-image: linear-gradient(
        30deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        150deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        30deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        150deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        60deg,
        #fff0df77 25%,
        transparent 25.5%,
        transparent 75%,
        #fff0df77 75%,
        #fff0df77
      ),
      linear-gradient(
        60deg,
        #fff0df77 25%,
        transparent 25.5%,
        transparent 75%,
        #fff0df77 75%,
        #fff0df77
      );
    background-size: 80px 140px;
    background-position:
      0 0,
      0 0,
      40px 70px,
      40px 70px,
      0 0,
      40px 70px;
    background-color: #fff8e9;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;

    .box {
      border-radius: 50px!important;
    }
  }

</style>
