<script>
  import modals from "./Helpers/modal-store";
  import WindowRound from "./UI/WindowRound.svelte";
  import Icon from "./UI/icon.svelte";
  import StatusBar from "./UI/StatusBar.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from "svelte/transition";

  const dispatcher = createEventDispatcher();

  let selectedTheme = "";
  let contentData = null;
  let rebooting = false;

  // onMount(() => {
  //   openModal("browser", "page_microblog")
  // })

  function closeModal(key) {
    modals.closeModal(key);
  }

  function openModal(id, shortcut = null) {
    modals.openModal(id, shortcut);
  }

  function onRestart() {
    rebooting = true;
    localStorage.removeItem("expiryDate");
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  function onTurnOff() {
    rebooting = true;
    localStorage.removeItem("expiryDate");
    setTimeout(() => {
      dispatcher("turnoff", selectedTheme);
    }, 2000);
  }

  function onFileClick(event) {
    contentData = event.detail;
  }
</script>

<main>
  {#if rebooting}
    <div class="overlay" in:fade={{ duration: 200 }}>
      <i class="fa-solid fa-circle-notch fa-spin" />
    </div>
  {/if}

  <StatusBar
    on:openSettings={() => openModal("config")}
    on:openTurnOff={() => openModal("turnoff")}
  />
  <div class="desktop">
    <div class="icon-grid">
      <Icon
        on:dblclick={() => openModal("browser")}
        icon="browser"
        label="Browser"
      >
      </Icon>
      <Icon
        on:dblclick={() => openModal("browser", "page_webxp")}
        icon="browser"
        isShortcut="true"
        label="Web Dev Portfolio"
      ></Icon>
      <Icon
        on:dblclick={() => openModal("folder")}
        icon="directory"
        label="File Explorer"
      ></Icon>
      <Icon
        on:dblclick={() => openModal("player")}
        icon="audio"
        label="Audio Player"
      ></Icon>
      <Icon
        on:dblclick={() => openModal("guestbook")}
        icon="guestbook"
        label="Guestbook"
      ></Icon>
      <Icon
        on:dblclick={() => openModal("browser", "page_microblog")}
        icon="browser"
        isShortcut="true"
        label="Kiwitter"
      ></Icon>
    </div>

    {#each $modals as modal (modal.key)}
      <!-- Window component -->
      <WindowRound on:close={() => closeModal(modal.key)} modalData={modal}>
        <!-- Window content changes -->
        {#if modal.component}
          <svelte:component
            this={modal.component}
            exportPage={modal.shortcut}
            on:fileClick={onFileClick}
            on:restart={onRestart}
            on:turnoff={onTurnOff}
            {contentData}
          />
        {/if}
      </WindowRound>
    {/each}
  </div>
</main>

<style lang="scss">
  main {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-image: var(--theme-wallpaper);
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    will-change: transform;
  }

  .desktop {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .icon-grid {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    width: max-content;
    flex-wrap: wrap;
    gap: 20px;
    padding: 15px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay i {
    color: var(--theme-color);
    font-size: 3em;
  }
</style>
