<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let icon;
  export let isShortcut = false;
  export let label = "";

  let isActive = false;
  const dispatch = createEventDispatcher();

  function onClick() {
    isActive = true;
  }

  function onClickOutside(event) {
    isActive = false;
  }

  function onDblClickIcon() {
    dispatch("dblclick");
  }

  onMount(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });
</script>

<div
  class="item"
  on:dblclick={onDblClickIcon}
  on:touchstart={onDblClickIcon}
  on:click|stopPropagation={onClick}
  class:active={isActive}
  title={label}
>
  <div class="icon">
    <div
      class="icon-wrapper"
      style={`
        mask-image: url("./img/icons/${icon}.png");
        -webkit-mask-image: url("./img/icons/${icon}.png");
      `}
    >
      <img class="icon-img" src="./img/icons/{icon}.png" alt="" />
    </div>
    {#if isShortcut}
      <span class="shortcut">
        <i class="fa-solid fa-share fa-fw" />
      </span>
    {/if}
  </div>
  <div class="label">
    {label}
  </div>
</div>

<style lang="scss">
  .item {
    text-align: center;
    width: 120px;
    font-family: "Source Sans Pro", sans-serif;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .item.active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--theme-color);
    opacity: 0.3;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .label {
    padding: 2px;
    background-color: rgba(256, 256, 256, 0.4);
    font-size: 0.8em;
    color: #181425;
    margin: 5px auto 0 auto;
    max-width: 100%;
    text-align: center;
    width: max-content;
    z-index: 1;
  }

  .icon {
    position: relative;
  }

  .icon-wrapper {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-color);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 50px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: 50px;
  }

  .icon-img {
    mix-blend-mode: hard-light;
    max-width: 50px;
    will-change: transform;
  }

  .shortcut {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #181425;
    background: #fff;
    padding: 1px 2px;
    border-radius: 3px;
    font-size: 0.5em;
    border: solid 2px #181425;
  }
</style>
