<script>
  import { createEventDispatcher } from "svelte";
  import ButtonRound from "../../UI/ButtonRound.svelte";

  const dispatch = createEventDispatcher();
  export let refresh = null;
  export let src = null;

  src;
  refresh;

  function goToPage(page) {
    dispatch("goTo", page);
  }
</script>

<div class="wrapper">
  <div class="content">
    <h1 class="status-code">404</h1>
    <p>Página não encontrada...</p>
    <ButtonRound on:click={() => goToPage("page_home")}>
      <i class="fas fa-home" />
      Voltar para a Home
    </ButtonRound>
  </div>
</div>

<style>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .content {
    text-align: center;
    max-height: 100%;
  }

  .status-code {
    font-size: 5em;
    margin: 15px 0 0 15px;
    /* transform: rotate(-8deg); */
  }
</style>
