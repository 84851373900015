<script>
  import { createEventDispatcher } from "svelte";
  import Browser404 from "./Browser404.svelte";

  const dispatch = createEventDispatcher();
  export let refresh = null;
  export let src = null;
  src;
  refresh;

  function goToPage(page) {
    dispatch("goTo", page);
  }
</script>

<div class="wrapper">
  <div class="background">
    <i class="fas fa-globe" />
  </div>
  <div class="content">
    <h1 class="title">
      Godzilla <span class="title-highlight">Kuroi</span>Fox
    </h1>
    <p>Start navigating through the links below!</p>

    <div class="shortcuts">
      <div class="shortcut-link" on:click={() => goToPage("page_blog")}>
        <div class="shortcut-icon">
          <i class="far fa-newspaper" />
        </div>
        <div class="shortcut-label">Blog</div>
      </div>
      <div class="shortcut-link" on:click={() => goToPage("page_webxp")}>
        <div class="shortcut-icon">
          <i class="fa-solid fa-flask" />
        </div>
        <div class="shortcut-label">
          Web Dev <br /> Portfolio
        </div>
      </div>
      <div class="shortcut-link" on:click={() => goToPage("page_art_gallery")}>
        <div class="shortcut-icon">
          <i class="fa-solid fa-brush" />
        </div>
        <div class="shortcut-label">
          Art Gallery
        </div>
      </div>
      <div class="shortcut-link" on:click={() => goToPage("page_contact")}>
        <div class="shortcut-icon">
          <i class="far fa-paper-plane" />
        </div>
        <div class="shortcut-label">Contact</div>
      </div>
      <div class="shortcut-link" on:click={() => goToPage("page_microblog")}>
        <div class="shortcut-icon">
          <i class="fas fa-kiwi-bird" />
        </div>
        <div class="shortcut-label">Kiwitter</div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .content {
    text-align: center;
    max-height: 100%;
    width: 100%;
    max-width: 450px;
    position: relative;
  }

  .shortcuts {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .shortcut-link {
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 5px 20px;
  }

  .shortcut-icon {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
    height: 80px;
    width: 80px;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
    border: solid 4px #181425;
    transition: background-color 0.1s linear;

    &:hover {
      background: var(--theme-color-dark);
    }
  }

  .shortcut-label {
    font-family: "Comfortaa", sans-serif;
    font-size: 0.7em;
    font-weight: bold;
    padding-top: 10px;
    color: #181425;
  }

  .title-highlight {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
  }

  .background {
    position: absolute;
    z-index: 0;
    bottom: -200px;
    right: -200px;

    i {
      font-size: 40em;
      opacity: 0.3;
      color: var(--theme-color);
    }
  }
</style>
